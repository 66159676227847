import React from "react";
import { NavLink } from "react-router-dom";
import "./SideDrawer.css";
import logo from "../../Images/jikooo-image.png";

function SideDrawer({ show, click }) {
  const sideDrawerClass = ["sidedrawer"];

  if (show) {
    sideDrawerClass.push("show");
  }

  return (
    <div className={sideDrawerClass.join(" ")}>
      <ul className="sidedraw_links" onClick={click}>
        <li>
          <NavLink to="/">
            <img src={logo} alt="logo" className="h-10" />
          </NavLink>
        </li>
        <li>
          <NavLink to="/" exact={true} activeClassName="is-active">
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/about" activeClassName="is-active">
            About Us
          </NavLink>
        </li>
        <li>
          <NavLink to="/features" activeClassName="is-active">
            Features
          </NavLink>
        </li>
        <li>
          <NavLink to="/contact" activeClassName="is-active">
            Contact Us
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default SideDrawer;
