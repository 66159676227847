import { useState } from "react";

const Accordion = ({ title, children }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="accordion-wrapper container">
      <div
        className={`accordion-title flex justify-between cursor-pointer items-center py-2 ${
          isOpen ? "open" : ""
        }`}
        onClick={() => setOpen(!isOpen)}
      >
        {title}
      </div>
      <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
        <div className="accordion-content py-2">{children}</div>
      </div>
    </div>
  );
};
const Faq = () => (
  <div className="myContainer mt-10">
    <div className="wrapper">
      <h2 className="text-2xl text-center font-medium text-slate-800">
        Frequently Asked Questions About Jikooo
      </h2>
      <div
        className="w-28 bg-primary mt-2 mb-8 mx-auto"
        style={{ height: 3 }}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <Accordion title="What is Jikooo?">
            <p>
              Jikooo is a platform which integrates across cloud and on-premises
              applications. It simplifies connectivity between key 3rd party
              enterprise applications and on-premises applications.
            </p>
          </Accordion>
          <Accordion title="Does Jikooo support all applications? ">
            <p>
              Jikooo is a middleware application that supports a range of
              applications, helping you connect existing systems to new ones.
              For a list of applications, you can integrate with Jikooo, please
              email us at{" "}
              <a className="text-primary pl-2" href="mailto: info@Jikooo.com">
                info@Jikooo.com
              </a>
            </p>
          </Accordion>

          <Accordion title="How do I use it?">
            <p>
              Please refer to the tutorial links on how to use Jikooo. For
              additional help, please send us an email.
            </p>
          </Accordion>
          <Accordion title="Is technical assistance available?">
            Our technical team is always on standby to show you the ropes. Feel
            free to send an email if you have questions on its use.
          </Accordion>
        </div>

        <div>
          <Accordion title="Can I modify the codes?">
            <p>
              Jikooo is a proprietary software, and hence no codes, in whole or
              in part may be modified. You ca send your suggestions to{" "}
              <a className="text-primary pl-2" href="mailto: info@Jikooo.com">
                info@Jikooo.com
              </a>
            </p>
          </Accordion>
          <Accordion title="Where can I find documentation for Jikooo?">
            <p>
              Please fill the contact us form, and our documentation would be
              sent to you.
            </p>
          </Accordion>
          <Accordion title="Is Jikooo an open-source application?">
            <p>
              Jikooo is designed by a team of engineers, looking to change how
              systems connect. The source codes for the project have not been
              made open at this time. If you would like to make suggestions on
              what features you want to see, please email us at
              <a className="text-primary pl-2" href="mailto: info@Jikooo.com">
                info@Jikooo.com
              </a>
            </p>
          </Accordion>
          {/* <Accordion title="Testing....">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint
            iusto, itaque repudiandae fugiat nostrum adipisci inventore
            obcaecati consequuntur voluptatem corporis temporibus voluptatibus,
            aut quia quibusdam dolores dolorem a alias error.
          </Accordion> */}
        </div>
      </div>
    </div>
  </div>
);

export default Faq;
