import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import whyJ from "../Images/ESB-INTERFACE1.png";
import Faq from "../Components/Faq";
import { Trial } from "../Components/Trial";
import { motion } from "framer-motion";
import { animationOne, transition } from "../Components/Animation";
import { Integrate } from "../Components/Integrate";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

export const About = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={animationOne}
      transition={transition}
    >
      {/* Banner */}
      <div className="banner text-white flex justify-center text-center items-center px-5">
        <div>
          <h3
            className="text-2xl md:text-4xl font-medium pb-8"
            data-aos="zoom-in"
          >
            About Us
          </h3>
        </div>
      </div>

      <div className="myContainer mt-10 pb-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div data-aos="fade-right" className="h-full w-full">
            <img src={whyJ} alt="about us" className="w-full h-full" />
          </div>

          <div className="flex items-center text-justify overflow-hidden">
            <div data-aos="fade-left" className="lg:text-base text-sm">
              <h2 className="text-slate-800 text-2xl md:text-3xl font-medium text-left">
                Why Jikooo?
              </h2>
              <ul className="list-disc pl-5">
                <li className="py-3 text-sm">
                  It increases organizational agility by reducing time-to-market
                  for new initiatives is one of the most common reasons that
                  companies implement Jikooo as the backbone of their IT
                  infrastructure. Jikooo architecture facilitates this by
                  providing a simple, well defined, "pluggable" system that
                  scales well.
                </li>
                <li className="text-sm">
                  In addition, Jikooo integration provides a way to leverage
                  your existing systems and expose them to new applications.
                </li>

                <li className="pt-3 text-sm">
                  Using Jikooo means that the core applications will communicate
                  with each other through the bus. This lowers the number of
                  point-to-point connection necessary to allow communication
                  between applications. Doing so, eases the analysis of simple
                  software.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="py-14" style={{ background: "#ebf5fc" }}>
        <div className="myContainer">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <FeatureItem
              num="01"
              title="Background workers"
              desc=" Jikooo creates background workers, automating the agent workload. Jikooo can be configured to fetch data from source to destination database seamlessly.."
              more=" Jikooo creates background workers, automating the agent workload. Jikooo can be configured to fetch data from source to destination database seamlessly, automatically shutting off after the assigned workload is complete. This eliminates the need for a manual kill switch or monitoring."
            />

            <FeatureItem
              num="02"
              title="Dashboard monitor"
              desc="Jikooo provides a variety of options to monitor and manage the server runtime from the Monitor tab in the management console, allowing a server administrator.."
              more="Jikooo provides a variety of options to monitor and manage the server runtime from the Monitor tab in the management console, allowing a server administrator to collect and view runtime statistical information from sequences, proxy services, and endpoints through the ESB management console."
            />

            <FeatureItem
              num="03"
              title="Entity creation / mapping"
              desc="Jikooo, creates a relationship between the source and destination systems, acting as the middleware that ensures data consistency amongst all related entities."
              more="Jikooo, creates a relationship between the source and destination systems, acting as the middleware that ensures data consistency amongst all related entities. With Jikooo, data needs not be replicated manually, and errors are eliminated during integration. Data uploaded on Jikooo is automatically replicated in source and destination databases."
            />

            <FeatureItem
              num="04"
              title="API documentation"
              desc="it is a proxy layer for your digital services which manages a variety of features via APIs. Jikooo is preferred for its orchestration, integration, and security capabilities"
              more="it is a proxy layer for your digital services which manages a variety of features via APIs. Jikooo is preferred for its orchestration, integration, and security capabilities"
            />
          </div>
        </div>
      </div>
      <Integrate />
      <Trial />

      <div data-aos="fade-up">
        <Faq />
      </div>
    </motion.div>
  );
};

const FeatureItem = ({ num, title, desc, more, more2 }) => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  return (
    <>
      <div class="card">
        <div class="box">
          <div class="content">
            <h2>{num}</h2>
            <h3>{title}</h3>
            <p>{desc}</p>
            <span href="!#" onClick={onOpenModal}>
              Read more
            </span>
          </div>
        </div>
      </div>

      <Modal open={open} onClose={onCloseModal} top>
        <div className="pt-7 px-3 pb-3">
          <h2 className="py-5 text-xl font-medium capitalize text-left">
            {title}
          </h2>
          <div className="text-justify text-sm lg:text-base">
            <p>{more}</p>
          </div>
        </div>
      </Modal>
    </>
  );
};
