import React from "react";
import "./navbar.css";
import logo from "../../Images/jikooo-image.png";
import { NavLink } from "react-router-dom";

const Navbar = ({ click }) => {
  return (
    <div className="bg-white shadow w-full top-0 sticky z-10">
      <nav className="navbar myContainer">
        <div className="flex">
          <NavLink to="/">
            <img src={logo} alt="logo" className="h-10" />
          </NavLink>
        </div>

        <div className="navLink_wrap">
          <NavLink
            exact={true}
            activeClassName="isActive"
            to="/"
            className="nav_link"
          >
            Home
          </NavLink>
          <NavLink activeClassName="isActive" to="/about" className="nav_link">
            About Us
          </NavLink>

          <NavLink
            activeClassName="isActive"
            to="/features"
            className="nav_link"
          >
            Features
          </NavLink>

          <NavLink
            activeClassName="isActive"
            to="/contact"
            className="nav_link"
          >
            Contact Us
          </NavLink>
        </div>

        <div className="flex">
          <NavLink
            to="/contact"
            className="bg-primary text-white rounded px-4 md:py-2 py-1 hover:bg-gray-800 transition ease-in-out duration-500"
            style={{ fontSize: 15.5 }}
          >
            Request a Demo
          </NavLink>

          <div className="hamburger_menu ml-3" onClick={click}>
            <i className="ri-menu-line text-2xl"></i>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
