import { Route, Switch, useLocation } from "react-router-dom";
import { Home } from "./Pages/Home";
import { About } from "./Pages/About";
import { Features } from "./Pages/Features";
import { Contact } from "./Pages/Contact";
import Layout from "./Components/Layout";
import NotFoundPage from "./Pages/NotFoundPage";

function App() {
  let location = useLocation();
  return (
    <Layout>
      <Switch location={location} key={location.pathname}>
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/features" component={Features} />
        <Route path="/contact" component={Contact} />
        <Route><NotFoundPage/></Route>
      </Switch>
    </Layout>
  );
}

export default App;
