import PropTypes from "prop-types";
import { useState } from "react";
import Footer from "./Footer";
import Backdrop from "./Navbar/Backdrop";
import Navbar from "./Navbar/Navbar";
import SideDrawer from "./Navbar/SideDrawer";
import StartTop from "./StartTop";
import { AnimatePresence } from "framer-motion";
import BackToTop from "./BackToTop";

const propTypes = {
  children: PropTypes.any.isRequired,
};
const Layout = ({ children }) => {
  const [sideToggle, setSideToggle] = useState(false);
  return (
    <div>
      <Navbar click={() => setSideToggle(true)} />
      <SideDrawer show={sideToggle} click={() => setSideToggle(false)} />
      <Backdrop show={sideToggle} click={() => setSideToggle(false)} />
      <BackToTop />
      <StartTop />
      <AnimatePresence exitBeforeEnter>
        <main>{children}</main>
      </AnimatePresence>
      <Footer />
    </div>
  );
};

Layout.propTypes = propTypes;

export default Layout;
