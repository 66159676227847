import React from "react";
import { Link } from "react-router-dom";
import "../Components/footer.css";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="mt-10">
      <div className="pg-footer ">
        <div className="footer">
          <svg
            className="footer-wave-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 100"
            preserveAspectRatio="none"
          >
            <path
              className="footer-wave-path"
              d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"
            ></path>
          </svg>
          <div className="myContainer">
            <div className="footer-content grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 py-10">
              <div className="footer-content-column">
                <h2 className="">About us</h2>

                <p className="text-sm text-left">
                  Jikooo is a platform which integrates across cloud and
                  on-premises applications. It simplifies connectivity between
                  key 3rd party enterprise applications and on-premises
                  applications.
                </p>
              </div>

              <div className="footer-content-column">
                <h2>Top Features</h2>
                <ul>
                  <li>Background workers</li>
                  <li>Dashboard monitors</li>
                  <li>API documentation</li>
                  <li>Entity creation</li>
                </ul>
              </div>
              <div className="footer-content-column">
                <h2> Quick Links</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/features">Features</Link>
                  </li>
                  <li>
                    <Link to="/contact">Request Demo</Link>
                  </li>
                </ul>
              </div>
              <div className="footer-content-column">
                <h2>Contact Us</h2>
                <ul>
                  <li>
                    <a href="mailto: info@snapnetsolutions.com" className="contact-link">
                    <i className="ri-mail-line"></i>
                     <span> Email Contact</span>
                    </a>
                  </li>
                  <li>
                    <a href="tel: +234 909 000 4652" className="contact-link">
                    <i className="ri-phone-line"></i>
                      <span>Call Contact</span></a>
                  </li>
                  <li>
                    <Link to="/contact" className="contact-link">
                    <i className="ri-contacts-book-2-line"></i>
                      <span>Contact Form</span></Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-copyright text-center bg-primary py-5 px-5">
            <p className="text-base font-medium">
              &copy; {year} Jikooo, All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
