import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slide1 from "../Images/slide-1.jpg";
import slide2 from "../Images/slide-2.jpg";
import slide3 from "../Images/slide-3-overlay.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function SampleNextArrow(props) {
  const { style } = props;
  return <div style={{ ...style, display: "none" }} />;
}

function SamplePrevArrow(props) {
  const { style } = props;
  return <div style={{ ...style, display: "none" }} />;
}

const HomeSlider = () => {
  let settings = {
    className: "",
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    autoplaySpeed: 6000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div className="text-white">
      <Slider {...settings}>
        <div>
          <div
            className="text-center w-full flex justify-center items-center px-3"
            style={{
              background: `url(${slide2})`,
              height: "calc(100vh - 65px)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              backgroundAttachment: "fixed",
            }}
          >
            <div>
              <h1 className="text-2xl lg:text-4xl font-semibold tracking-wide">
                Data orchestration between external
                <br className="md:flex hidden" />
                systems.
              </h1>
              <p className="py-4 text-sm">
                Integrate, manage and replicate multiple data sources using
                Jikooo. <br className="md:flex hidden" />
                Detect and remove duplicate data, keeping your database clean.
              </p>
              <div className="flex justify-center space-x-5">
                <Link
                  to="/about"
                  className="py-2 px-5 rounded-md border-2 hover:bg-primary hover:border-primary border-white transition ease-in-out duration-500"
                >
                  Learn More
                </Link>
                <Link
                  to="/contact"
                  className="py-2 px-5 rounded-md bg-primary border-2 border-primary hover:bg-transparent hover:border-white transition ease-in-out duration-500"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* // slide 3 */}
        <div>
          <div
            className="text-center w-full flex justify-center items-center px-3"
            style={{
              background: `url(${slide1})`,
              height: "calc(100vh - 65px)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              backgroundAttachment: "fixed",
            }}
          >
            <div>
              <h1 className="text-2xl lg:text-4xl font-semibold tracking-wide">
                Entity creation/field mapping and
                <br className="md:flex hidden" /> background workers.
              </h1>
              <p className="py-4 tracking-wide text-sm">
                Create dependable agents using Jikooo to keep your data
                consistent between <br className="md:flex hidden" /> source and
                destination.
              </p>
              <div className="flex justify-center space-x-5">
                <Link
                  to="/contact"
                  className="py-2 px-5 rounded-md bg-primary border-2 border-primary hover:bg-transparent hover:border-white transition ease-in-out duration-500"
                >
                  Get Started
                </Link>
                <Link
                  to="/about"
                  className="py-2 px-5 rounded-md border-2 hover:bg-primary hover:border-primary border-white transition ease-in-out duration-500"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className="text-center w-full flex justify-center items-center px-3"
            style={{
              background: `url(${slide3})`,
              height: "calc(100vh - 65px)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              backgroundAttachment: "fixed",
            }}
          >
            <div>
              <h1
                className="text-2xl lg:text-4xl font-semibold tracking-wide"
                data-aos="zoom-in"
              >
                Do more with less
              </h1>
              <p className="py-4 tracking-wide text-sm">
                Handle complex 3rd party data connectivity and integration{" "}
                across multiple <br className="md:flex hidden" /> sources with
                just one platform. See how it works.
              </p>
              <div className="flex justify-center space-x-5" data-aos="fade-up">
                <Link
                  to="/contact"
                  className="py-2 px-5 rounded-md bg-primary border-2 border-primary hover:bg-transparent hover:border-white transition ease-in-out duration-500"
                >
                  Get Started
                </Link>
                <Link
                  to="/about"
                  className="py-2 px-5 rounded-md border-2 hover:bg-primary hover:border-primary border-white transition ease-in-out duration-500"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default HomeSlider;
