import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";
import { animationOne, transition } from "../Components/Animation";
import { Integrate } from "../Components/Integrate";

export const Features = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={animationOne}
      transition={transition}
    >
      {/* Banner */}
      <div className="banner text-white flex justify-center text-center items-center px-5">
        <div>
          <h3
            className="text-2xl md:text-4xl font-medium pb-8"
            data-aos="zoom-in"
          >
            Key Features
          </h3>
        </div>
      </div>

      <div className="myContainer mt-14 mb-24">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <FeaturesItem
            image="https://img.icons8.com/fluency/64/000000/outgoing-data.png"
            title="Data orchestration between external systems"
            desc="Jikooo automates processes related to managing data, such as combining data from multiple sources, and preparing it for data analysis. It can also include tasks like provisioning resources and monitoring."
          />

          <FeaturesItem
            image="https://img.icons8.com/external-ddara-flat-ddara/64/000000/external-api-fintech-ddara-flat-ddara.png"
            title="API documentation and testing"
            desc="it is a proxy layer for your digital services which manages a variety of features via APIs. Jikooo is preferred for its orchestration, integration, and security capabilities"
          />

          <FeaturesItem
            image="https://img.icons8.com/external-parzival-1997-outline-color-parzival-1997/64/000000/external-creation-digital-globalization-parzival-1997-outline-color-parzival-1997.png"
            title="Entity creation and Field mapping"
            desc="Jikooo, creates a relationship between the source and destination systems, acting as the middleware that ensures data consistency amongst all related entities. With Jikooo, data needs not be replicated manually, and errors are eliminated during integration. Data uploaded on Jikooo is automatically replicated in source and destination databases."
          />

          <FeaturesItem
            image="https://img.icons8.com/external-inipagistudio-lineal-color-inipagistudio/64/000000/external-dashboard-business-activity-inipagistudio-lineal-color-inipagistudio.png"
            title="Dashboard Monitors"
            desc=" Jikooo provides a variety of options to monitor and manage the server runtime from the Monitor tab in the management console, allowing a server administrator to collect and view runtime statistical information from sequences, proxy services, and endpoints through the ESB management console."
          />

          <FeaturesItem
            image="https://img.icons8.com/external-icongeek26-linear-colour-icongeek26/64/000000/external-workers-project-work-icongeek26-linear-colour-icongeek26.png"
            title="Background workers Creation"
            desc="Jikooo creates background workers, automating the agent workload. Jikooo can be configured to fetch data from source to destination database seamlessly, automatically shutting off after the assigned workload is complete. This eliminates the need for a manual kill switch or monitoring."
          />

          <FeaturesItem
            image="https://img.icons8.com/external-kmg-design-flat-kmg-design/64/000000/external-synchronization-arrows-kmg-design-flat-kmg-design.png"
            title="Data synchronization between API's"
            desc="Jikooo allows you to keep a local copy of all content in a space up to date via delta updates, or content that has changed. Without a Sync API, applications require an ongoing internet connection and must constantly download all data in each synchronization, including content they are already aware of."
          />
        </div>
      </div>

      <Integrate />
      <br />
      <br />
    </motion.div>
  );
};

const FeaturesItem = ({ image, title, desc }) => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div
      className="shadow hover:shadow-inner shadow-red-100 px-7 py-6 border  bg-slate-50"
      data-aos="fade-up"
    >
      <div className="flex justify-center">
        <img src={image} alt="features" />
      </div>
      <div>
        <h3 className="py-4 font-medium text-slate-800 text-lg text-center">
          {title}
        </h3>
        <p className="text-sm text-justify">{desc}</p>
      </div>
    </div>
  );
};
