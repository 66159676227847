import React, { useEffect, useRef, useState } from "react";
import { animationOne, transition } from "../Components/Animation";
import { motion } from "framer-motion";
import Aos from "aos";
import "aos/dist/aos.css";
import bg from "../Images/contact-us.jpg";
import emailjs from "@emailjs/browser";
import swal from "@sweetalert/with-react";

const initialState = {
  fullName: "",
  contact: "",
  emailAddress: "",
  subject: "",
  message: "",
};

export const Contact = () => {
  const form = useRef();
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  const [person, setPerson] = useState(initialState);

  const handleChange = (e) => {
    setPerson({ ...person, [e.target.name]: e.target.value });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_s7n2nxc",
        "template_1wye3z2",
        form.current,
        "user_BQN6U2OITjmL8kfM03Aix"
      )
      .then(
        (result) => {
          swal("Message sent!", "Thank you!", "success");
        },
        (error) => {
          swal(error.text, "Please try again!", "error");
        }
      );

    setPerson(initialState);
  };

  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={animationOne}
      transition={transition}
    >
      {/* Banner */}
      <div
        className="text-slate-800 py-20"
        style={{
          background: `url(${bg})`,
          width: "100%",
          backgroundPosition: "bottom center",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="myContainer">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div
              className="bg-white text-center px-5 py-6 rounded"
              data-aos="zoom-in"
            >
              <i className="ri-map-pin-line text-3xl"></i>
              <h3 className="font-medium text-xl pt-2">Address</h3>
              <p className="text-sm pt-2">
                1b Abayomi Shonuga Crescent, Off Dele Adedeji Street, Lekki
                Phase 1, Lagos, Nigeria.
              </p>
            </div>

            <div
              className="bg-white text-center px-5 py-6 rounded"
              data-aos="zoom-in"
            >
              <i className="ri-phone-line text-3xl"></i>
              <h3 className="font-medium text-xl py-2">Address</h3>
              <div className="">
                <a href="tel: +234 909 000 4652">+234 909 000 4652</a>
              </div>
            </div>

            <div
              className="bg-white text-center px-5 py-6 rounded"
              data-aos="zoom-in"
            >
              <i className="ri-mail-line text-3xl"></i>
              <h3 className="font-medium text-xl py-2">Address</h3>
              <a href="mailto: info@snapnetsolutions.com">
                info@snapnetsolutions.com
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-12 pb-16 mb-20" style={{ background: "#ebf5fc" }}>
        <div className="myContainer">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="bg-white px-5 py-6 shadow border">
              <h2 className="text-lg font-semibold text-slate-800 ">
                Write us a message
              </h2>
              <form ref={form} onSubmit={sendEmail}>
                <div class="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
                  <div>
                    <label className="text-gray-700" htmlFor="fullName">
                      Full Name
                    </label>
                    <input
                      id="fullName"
                      type="text"
                      name="fullName"
                      value={person.fullName}
                      onChange={handleChange}
                      required
                      className="form_input"
                    />
                  </div>

                  <div>
                    <label className="text-gray-700" htmlFor="emailAddress">
                      Email Address
                    </label>
                    <input
                      id="emailAddress"
                      name="emailAddress"
                      value={person.emailAddress}
                      onChange={handleChange}
                      type="email"
                      required
                      className="form_input"
                    />
                  </div>

                  <div>
                    <label
                      className="text-gray-700 dark:text-gray-200"
                      htmlFor="contact"
                    >
                      Contact
                    </label>
                    <input
                      id="contact"
                      type="tel"
                      name="contact"
                      value={person.contact}
                      onChange={handleChange}
                      required
                      className="form_input"
                    />
                  </div>

                  <div>
                    <label
                      className="text-gray-700 dark:text-gray-200"
                      htmlFor="subject"
                    >
                      Subject
                    </label>
                    <input
                      id="subject"
                      name="subject"
                      value={person.subject}
                      onChange={handleChange}
                      type="text"
                      required
                      className="form_input"
                    />
                  </div>
                </div>
                <div className="w-full mt-4">
                  <label className="block mb-2 text-gray-600">Message</label>

                  <textarea
                    name="message"
                    value={person.message}
                    onChange={handleChange}
                    required
                    className="h-36 form_input resize-none"
                  ></textarea>
                </div>
                <div className="flex justify-center mt-6">
                  <button
                    type="submit"
                    className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-primary rounded-md hover:bg-rose-600 focus:outline-none "
                  >
                    Send Message
                  </button>
                </div>
              </form>
            </div>
            <div className="">
              {" "}
              {/* Google map */}
              <iframe
                width="100%"
                height="100%"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=1b%20Abayomi%20Shonuga%20Crescent,%20Off%20Dele%20Adedeji%20Street,%20Lekki%20Phase%201,%20Lagos,%20Nigeria.&t=&z=13&ie=UTF8&iwloc=&output=embed"
                frameBorder="0"
                scrolling="no"
                // marginHeight="0"
                // marginWidth="0"
                title="google map"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
