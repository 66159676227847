export const animationOne = {
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    }
  };
  
  export const transition = {
    duration: 0.6,
    stiffness: 50
  };
  