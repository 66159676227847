import React, { useEffect } from "react";
import oracle from "../Images/oracle-cloud-logo.png";
import office from "../Images/office.png";
import azure from "../Images/azure.png";
import oneDrive from "../Images/oned.png";
import sp from "../Images/sp.png";
import Aos from "aos";
import "aos/dist/aos.css";

export const Integrate = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div className="myContainer mt-10" data-aos="fade-up">
      <h2 className="text-3xl text-center font-medium text-slate-800">
        Integrate Jikooo with
      </h2>
      <div
        className="w-24 bg-primary mt-2 mb-8 mx-auto"
        style={{ height: 3 }}
      />
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-5 gap-6">
        <div className="flex justify-center">
          <img src={oracle} alt="oracle" className="h-16" />
        </div>

        <div className="flex justify-center">
          <img src={azure} alt="oracle" className="h-20" />
        </div>

        <div className="flex justify-center">
          <img src={oneDrive} alt="oracle" className="h-20" />
        </div>

        <div className="flex justify-center">
          <img src={office} alt="oracle" className="h-28" />
        </div>
        <div className="flex justify-center">
          <img src={sp} alt="oracle" className="h-32" />
        </div>
      </div>
    </div>
  );
};
