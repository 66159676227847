import React from 'react'
import { Link } from 'react-router-dom'

export const Trial = () => {
    return (
        <div className="bg-slate-800 py-16 my-20 text-gray-300 text-base">
        <div className="myContainer">
          <div className="flex lg:flex-row flex-col lg:space-x-52 lg:items-center">
            <div className='text-sm lg:text-base'>
              <h4>Want to see how Jikooo works?</h4>
              <h2 className="text-2xl md:text-3xl py-3 text-white">
                30-Day Free Trial
              </h2>
              <p>
                Fill in the appropriate information and we will get back to you
                shortly.
              </p>
            </div>

            <div className="mt-10 lg:mt-0">
              <Link to="/contact" className="py-2 animate-bounce px-10 text-xl text-white rounded-md bg-primary border-2 border-primary hover:bg-transparent hover:border-white transition ease-in-out duration-500">
                30-Day Free Trial
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
}
